export default async function sendToTg(req, res) {
  if (req.method === 'POST') {
    try {

      const token = '7444623997:AAEL7icSvaPfZcxxNoJTpZ62typv-nWyK-0'
      const chatId = '-4799676245'
      const messageTg = `Name: ${req.body.name || '—'}
      %0APhone or Email: ${req.body.phone || req.body.email}
      %0ARequest: ${req.body.comment || '—'}
      %0APost Code: ${req.body.code || '—'}
      `
      const url =
        `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${messageTg}&parse_mode=HTML`

      const res = await fetch(url)
      return res
    } catch (err) {
      res.status(401).json(err)
    }
  }
}
