/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { GalleryContext, ListofJobsContext, ModalContext } from '../../App'
//initialData
import { initialStateListOfJobs, initialStateGallery, HOME, initialStateModal } from '../../utils/data'
//icon
import { ReactComponent as Smartphone } from '../../image/smartphone.svg'
import newLogo from '../../image/newLogo.svg'
import { ReactComponent as Burger } from '../../image/burger.svg'
//styles
import styles from './index.module.css'

const Header = () => {
  const navigate = useNavigate()
  const { setProjectName } = useContext(GalleryContext)
  const { setListOfJobs } = useContext(ListofJobsContext)
  const { setModal } = useContext(ModalContext)

  const setHome = () => {
    navigate(HOME)
    setListOfJobs(initialStateListOfJobs)
    setProjectName(initialStateGallery)
  }

  const getQuote = () => setModal({ ...initialStateModal, contactUsIsOpen: true })
  const mobileMenu = () => setModal({ ...initialStateModal, mobileMenuIsOpen: true })

  return<div className={styles.main}>
    <div className={styles.mainContainer} id={HOME}>
      <div className={styles.logoBlock}>
        <div className={styles.logo}>
          <a href={HOME} onClick={setHome}><img width={100} src={newLogo} alt='logo' /></a>
        </div>
        <div className={`${styles.line} nameLine`}><p>Gardens 24/7</p></div>
      </div>
      <div className={styles.communication} >
        <div className={`${styles.tell} tell`}>
          <a className={styles.phone} href='tel:073 928 85 771'>
            <Smartphone style={{ marginRight: '5px' }} /> 073 928 85 771
          </a>
        </div>
        <button onClick={getQuote} className={styles.quoteHeaderBtn}>Book a service</button>
      </div>
      <div className={`${styles.mobile} mobile`}>
        <button className={styles.close} onClick={mobileMenu}><Burger /></button>
      </div>
    </div>
  </div>
}
export default Header