import React from 'react'
import { useLocation } from 'react-router-dom'
//data
import { CONTACT } from '../../utils/data'
//styles and svg
import contactUs from '../../image/contact/contactUs.png'
import line from '../../image/contact/line.png'
import Telegram from '../../image/contact/Telegram.svg'
import Viber from '../../image/contact/Viber.svg'
import Whatsapp from '../../image/contact/Whatsapp.svg'
import Gmail from '../../image/contact/Gmail.svg'
import tabletLine from '../../image/contact/tabletLine.svg'
import styles from './index.module.css'

const ContactUs = () => {
  const { pathname } = useLocation()
  const social = [
    { img: Telegram },
    { img: Viber },
    { img: Whatsapp },
  ]

  const getClass = pathname === CONTACT ? styles.activeMain : styles.main

  return(
    <div className={ getClass } id='contact'>
      <div className={styles.phoneMobill}>
        <a href='tel:073 928 85 771' className={styles.tell}>
          <strong>073 928 85 771</strong>
        </a>

        <ul className={styles.social}>
          {social.map(el => {
            return(
              <li key={el.img}>
                <img width={25} src={el.img} alt='foto' />
              </li>
            )
          })}
          <li>
            <a className={styles.mail} href='mailto:info@gardens247.co.uk'>
              <img width={25} src={Gmail} alt='foto' />
            </a>
          </li>
        </ul>

      </div>
      <div className={styles.image}>
        <img src={contactUs} width='100%' alt='img' />
      </div>
      <div className={styles.contact}>
        <h1>Contact Us</h1>
        <a className={styles.mail} href='mailto:info@gardens247.co.uk'>mail: info@gardens247.co.uk</a>
        <img className={styles.icon} src={line} alt='line' />
        <img className={styles.tabletIcon} src={tabletLine} alt='line' />

        <div className={styles.phone}>
          <a href='tel:073 928 85 771' className={styles.tell}>
            <strong>073 928 85 771</strong>
          </a>

          <ul className={styles.social}>
            {social.map(el => (<li key={el.img}>
              <img width={25} src={el.img} alt='foto' />
            </li>))}
            <li>
              <a className={styles.mail} href='mailto:info@gardens247.co.uk'>
                <img width={25} src={Gmail} alt='foto' />
              </a>
            </li>
          </ul>

        </div>
      </div>
    </div>
  )
}

export default ContactUs