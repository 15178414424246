import React, { useContext, useState, useRef, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useInView, motion } from 'framer-motion'
// components
import CategoryJobs from '../categoryJobs'
import ContentCategoryListOfJobs from '../content'
import { ListofJobsContext } from '../../../App'
import ListofJobs from '..'
// data
import { initialStateListOfJobs, JOBS } from '../../../utils/data'
//styles and svg
import { ReactComponent as BackOrange } from '../../../image/backOrange.svg'
import { ReactComponent as ToTop } from '../../../image/toTop.svg'
import stylesList from './index.module.css'
import styles from './index.module.css'

const Jobs = () => {
  const { pathname } = useLocation()
  const { setListOfJobs, listOfJobs } = useContext(ListofJobsContext)
  const [isVisible, setIsVisible] = useState(true)
  const ref = useRef(null)
  const isInView = useInView(ref)

  useEffect(() => {
    setIsVisible(isInView)
  }, [isInView])

  const backFnc = () => {
    setListOfJobs(initialStateListOfJobs)
    toTop()
  }

  const vrnBtn = {
    hidden: { display: 'none' },
    enter: { display: 'block' },
  }

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const visible = !isVisible ? 'enter' : 'hidden'
  const isVisibleBtn = listOfJobs.open || listOfJobs.about
  const getClass = pathname === JOBS ? styles.activeMain : styles.mainJob
  const getClassBackMob = `${stylesList.titleContainer} ${stylesList.titleContainerMobile}`
  const getClassBack = `${styles.titleContainer} ${styles.titleContainerDesctop}`
  const space = { marginRight: '5px' }
  const style = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
  }

  const BtnToTop = () => <motion.div
    transition={{ duration: 0.3 }}
    variants={vrnBtn}
    animate={visible}
    initial='enter'
    style={style}
  >
    <button onClick={toTop} className={styles.toTop} >
      <ToTop />
    </button>
  </motion.div>

  return (<>
    <h1 id='back'>List of Services</h1>
    <div ref={ref} />

    <div className={getClass} id='job'>
      {!!listOfJobs?.name &&
      <div className={getClassBack}>
        <button id='btnBack' className={styles.btnArrow} onClick={backFnc}>
          <BackOrange className={styles.arrow} /> back
        </button>
        <h1>{listOfJobs?.name}</h1>
      </div>
      }

      <div className={styles.containerListOFJobs}>
        <ListofJobs backFnc={backFnc} />
        {/* mobile */}
        <div className={getClassBackMob}>
          <h1>{listOfJobs?.name}</h1>
          {isVisibleBtn && <button id='btnBack' onClick={backFnc}><BackOrange style={space} /> back</button>}
        </div>

        {listOfJobs.open && <CategoryJobs /> }
        {listOfJobs.about && <ContentCategoryListOfJobs />}
        {listOfJobs.name && <BtnToTop />}
      </div>
    </div>
  </>
  )
}

export default Jobs
