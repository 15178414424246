/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { initialStateModal } from '../../../utils/data'
import { useNavigate } from 'react-router-dom'
import { ModalContext } from '../../../App'
import { ReactComponent as Close } from '../../../image/ModalHero/closeModal.svg'
import styles from '../index.module.css'

const SuccessModal = () => {
  const { setModal } = useContext(ModalContext)
  const navigate = useNavigate()

  const closeFnc = () => {
    const container = document?.getElementById('modalSection')
    if (container) {
      container.className = styles.modalGoodFinishAnimation
    }
    setTimeout(() => {
      setModal(initialStateModal)
      navigate(-1)
    }, 400)
  }

  return <>
    <div onClick={closeFnc} className={styles.overlay}>
      <div id='modalSection' onClick={e => e.stopPropagation()} className={styles.thanksSection}>
        <div className={styles.borderSection}>
          <div className={styles.backgroundThanks} />
          <div className={styles.formThanks}>
            <button className={styles.closeThanks} onClick={ closeFnc}>
              <Close />
            </button>
            <h1>Thank you</h1>
            <h2>we will contact you</h2>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default SuccessModal