import React from 'react'
import { useContext } from 'react'
import { ListofJobsContext } from '../../../App'
import { aboutJobs } from '../../../utils/data'
import styles from '../Jobs/index.module.css'

const ContentCategoryListOfJobs = () => {
  const { listOfJobs } = useContext(ListofJobsContext)
  const data = aboutJobs[listOfJobs.name]
  const { imgFirst, imgSecondary } = aboutJobs[listOfJobs.name]

  return <div className={styles.container}>
    {!!data?.description && <div className={styles.contentConteiner}>
      { !data.isHiddenTitle && <h2 className={styles.description}>Description:</h2>}
      <div className={styles.textInfo}>{data?.description}</div>
    </div>}

    {(!!imgFirst || !!imgSecondary) &&
      <div className={`${styles.contentConteiner} ${styles.imgConteiner}`}>
        {!!imgFirst && imgFirst}
        {!!imgSecondary && imgSecondary}
      </div>
    }

    {!!data.titleList && data.titleList}
    {!!data?.list &&
      <div className={`${styles.contentConteiner} ${styles.listConteiner}`}>
        <h3 className={styles.subtitle} style={{ paddingBottom:' 5px' }}>{data?.subtitle}</h3>
        {data?.list.map((el, i) => {
          return <div key={i}>
            {!el?.icon && <div className={styles.point} />}
            {el?.icon && el?.icon}
            {el.title}
            {el?.price}
          </div>
        })}
      </div>
    }

    {!!data.priceTitle && data.priceTitle}
    {!!data.listSecondary && (
      <div
        className={`${styles.contentConteiner} ${styles.listConteiner}`}
        style={{ paddingBottom: '30px', display: 'flex', flexDirection: 'column' }}
      >
        <h3 className={styles.subtitle} style={{ paddingBottom: '5px' }}>
          {data?.subtitle}
        </h3>
        {data?.listSecondary.map((el, i) => {
          return (
            <div key={i}>
              {!el?.icon && <div className={styles.point} />}
              {el?.icon && el?.icon}
              {el.title}
              {el?.price}
            </div>
          )
        })}
      </div>
    )}

    {!!data.attention && data.attention}

    {(!!data?.imgThird || !!data?.imgFourth) &&
      <div className={`${styles.contentConteiner} ${styles.imgConteiner}`}>
        {!!data?.imgThird && data?.imgThird}
        {!!data?.imgFourth && data?.imgFourth}
      </div>
    }
    {!!data?.palette && <div className={`${styles.contentConteiner } ${styles.paletteConteiner}`}>
      {data.palette.map((el,i) => {
        return <div key={i} className={styles.palette}>
          {el?.icon && <img width='100%' src = {el?.icon} />}
          {el?.title && <span>{el?.title}</span>}
        </div>
      })}
    </div>
    }
    {!!data?.imagesVertical &&
      <div className={`${styles.contentConteiner} ${styles.imagesVertical}`}>
        {data?.imagesVertical.map(i => <img key={i} src={i} />)}
      </div>
    }
    {!!data?.images &&
      <div className={`${styles.contentConteiner} ${styles.imgesConteiner}`}>
        {data?.images.map(i => <img key={i} width='100%' src={i} />)}
      </div>
    }
    {!!data?.lastImg && <img className={styles.lastImgContent} alt='i' src={data?.lastImg} />
    }
    {!!data?.price && <div className={`${styles.contentConteiner } ${styles.priceConteiner}`}>{data?.price}</div>}
  </div>
}

export default ContentCategoryListOfJobs