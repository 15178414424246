import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import GalleryList from '../components/GalleryPage/galleryList'
import ShowAllGallery from '../components/GalleryPage/ShowAll'
import { GalleryContext } from '../App'
//data
import { HOME, initialStateGallery, isMobile } from '../utils/data'
//styles and svg
import { ReactComponent as BackOrange } from '../image/backOrange.svg'
import styles from '../components/ListofJobs/Jobs/index.module.css'

export const GalleryPage = () => {
  const navigate = useNavigate()
  const { projectName, setProjectName } = useContext(GalleryContext)
  const [visited, setVisited] = useState([])
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState(isMobile ? 5000 : 4000)
  const isInside = !!visited?.find(() => projectName?.name)

  const backButton = () => {
    if (window.innerWidth <= 700) {
      if(!projectName.name){
        navigate(HOME)
      }
      if(!!projectName.name) {
        setProjectName(initialStateGallery)
        setLoading(false)
        return
      }
    } else {
      navigate(HOME)
    }
  }

  const isVisible = (window.innerWidth <= 700 && !projectName.name) || (window.innerWidth > 700)

  return <div>
    <div className='containerGallery'>
      <h2 className='title'>Gallery</h2>
    </div>

    {projectName.name &&
      <div id='titleContainer'>
        <button onClick={backButton} className={styles.btnArrow}>
          <BackOrange className={styles.arrow} />back</button>
        <h1>{projectName?.name}</h1>
      </div>}

    <div className='galleryContainer'>
      {isVisible && <GalleryList setTime={setTime} isInside={isInside} visited={visited} setVisited={setVisited} />}
      <ShowAllGallery time={time} setLoading={setLoading} loading={loading} />
    </div>
  </div>
}