import React, { useState, useEffect, useRef, useContext } from 'react'
import { ModalContext } from '../../App'
//data
import { initialStateModal } from '../../utils/data'
//styles and svg
import second from '../../image/slider/second.jpg'
import third from '../../image/slider/third.jpg'
import fifth from '../../image/slider/fifth.jpg'
import sixth from '../../image/slider/sixth.jpg'
import seventh from '../../image/slider/seventh.jpg'
import eighth from '../../image/slider/eighth.jpg'
import styles from './index.module.css'

const Hero = () => {
  const { setModal } = useContext(ModalContext)
  const [index, setIndex] = useState(0)
  const timeoutRef = useRef(null)

  const banner = [
    second ,
    third ,
    fifth ,
    sixth ,
    seventh ,
    eighth ,
  ]
  const delay = 5000
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }
  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex(prevIndex =>
          prevIndex === banner.length - 1 ? 0 : prevIndex + 1,
        ),
      delay,
    )

    return () => {
      resetTimeout()
    }
  }, [index])

  return<>
    <div id='home'>
      <div className={styles.container}>
        <div className={styles.hero}>
          <div
            className={styles.slideshowSlider}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            {banner?.map((el, i) => (
              <img className={styles.image} width='100%' key={i} src={el} />
            ))}
          </div>
        </div>
        <div className={styles.titleContainer} >
          <div className={styles.title}>
            <h1>Transforming gardens is what we do best</h1>
            <h2 className={styles.subTitle}>
                 why not try and put us to the test
                 guaranteed you will forget rest
                 we work with 100% zestPlease be our guest.
            </h2>
          </div>
          <button onClick={() => setModal({ ...initialStateModal, contactUsIsOpen: true })}
            className={styles.quoteBtn}
          >
              Get free quote
          </button>
        </div>
      </div>
    </div>
  </>
}
export default Hero
