import React, { useContext } from 'react'
import { PageContext } from '../../App'
import Header from '../Header'

const Footer = () => {
  const { page } = useContext(PageContext)

  return <div style={{ display: page !== 'home' ? 'none' : 'flex' }} className='footer'>
    <Header />
  </div>

}

export default Footer