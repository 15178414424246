/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { navLinks } from '../../../utils/data'
import styles from './index.module.css'
import ListofJobs from '../../ListofJobs'

const ShopAll = ({ className }) => {

  return (
    <>
      <container className={`${className} shopList`}>
        <ListofJobs btnIsVisible={false} />
      </container>
    </>
  )
}

const NavList = () => {
  const [close, setClose] = useState(null)
  const { pathname } = useLocation()

  const getClass = link => link === pathname ? styles.active : styles.navItem

  return <>
    <div className={`${styles.list} ${pathname !== '/' ? 'mb': 0}`}>
      <ul className={styles.navList}>
        {navLinks.map(({ name, path }) => {
          return <li onClick={() => setClose(false)} onMouseEnter={() => {
            if (name === 'list of services') {
              setClose(true)
            } else {
              setClose(false)
            }
          }} key={name} className={`${getClass(path)} ${pathname !== path ? 'shoopAllLink' : ''}`}
          >
            <a href={path} className={styles.link}>{name}</a>

          </li>
        })}
        <ShopAll className={`${close ? 'open overlay' : ''} `} />
      </ul>
    </div>
  </>
}
export default NavList