import React from 'react'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ModalContext } from '../../../App'
import { initialStateModal, navLinks } from '../../../utils/data'
// svg and styles
import { ReactComponent as Close } from '../../../image/ModalHero/closeModal.svg'
import logo from '../../../image/logo.svg'
import styles from './index.module.css'

const MobileMenu = () => {
  const { pathname } = useLocation()
  const { setModal } = useContext(ModalContext)

  const closeFnc = () => {
    const container = document?.getElementById('mobileMenu')
    if (container) {
      container.className = styles.modalFinishAnimation
    }
    setTimeout(() => {
      setModal(initialStateModal)
    }, 400)
  }

  const getClass = link => link === pathname ? styles.active : styles.navItem

  return(
    <>
      <div onClick={closeFnc} className={styles.modalContainer}>
        <div id='mobileMenu' onClick={e => e.stopPropagation()} className={styles.modal}>
          <a href='/' >
            <img width={70} src={logo} className={styles.logo} alt='logo' />
          </a>

          <button onClick={closeFnc} className={styles.close}>
            <Close width={20} />
          </button>

          <ul className={styles.navList}>
            {navLinks.map(({ name, path }) => (
              <li key={name} className={getClass(path)}>
                <a href={path} className={styles.link}>{name}</a>
              </li>
            ))}

            <button type='button'
              onClick={() => setModal({ ...initialStateModal, contactUsIsOpen: true })}
              className={styles.quoteNavBtn}
            >
              Get free quote
            </button>
          </ul>

        </div>
      </div>
    </>
  )
}

export default MobileMenu