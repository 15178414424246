/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ModalContext } from '../../App'
import { ReactComponent as Close } from '../../image/ModalHero/closeModal.svg'
import { initialStateModal, regEmail } from '../../utils/data'
import sendToTg from '../../api/index'
import styles from './index.module.css'

const ModalHero = () => {
  const [data, setData] = useState({ contact: 'mail' })
  const [hasError, setHasError] = useState({})
  const [loading, setLoading] = useState(false)
  const error = Object.values(hasError)
  const { setModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const location = useLocation()
  const inputsName = [
    { label: 'Name surname', name: 'name' },
    { label: 'post code', name: 'code' },
  ]

  function del(val) {
    const obj = { ...hasError }
    for (const k of Object.keys(obj)) {
      if (k === val) {
        delete obj[k]
      }
    }
    setHasError(obj)
  }

  const onChange = e => {
    setLoading(false)
    setData({ ...data, [e?.target?.name]: e?.target?.value })
    if (e?.target?.name === 'contact') {
      if (!!hasError?.email) {
        del('email')
        return
      }
      if (!!hasError?.phone) {
        del('phone')
        return
      }
    }
    del(e?.target?.name)
  }

  const isValid = () => {
    // eslint-disable-next-line prefer-const
    let errors = {}
    // name
    if (!data?.name) {
      errors.name = 'This field is required'
    } else if (data?.name?.length < 3) {
      errors.name = 'Field must contain at least 3 letters'
    } else if (data?.name?.length > 40) {
      errors.name = 'The field must contain no more than 40 letters'
    }
    //email
    if (data?.contact === 'mail') {
      if (!data?.email) {
        errors.email = 'This field is required'
      } else if (!regEmail.test(data?.email)) {
        errors.email = 'Not valid email'
      }
    } else {
      //phone
      if (!data?.phone) {
        errors.phone = 'This field is required'
      }
    }
    //code
    if (!data?.code) {
      errors.code = 'This field is required'
    }

    if (Object.values(errors).length) {
      setHasError(errors)
      return false
    }
    return true
  }

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const isValidData = await isValid()
    if (isValidData) {
      try {
        const resp = await sendToTg({
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: data,
        })
        if (resp.status !== 200) {
          throw resp
        } else {
          setLoading(false)
          const container = document?.getElementById('modalSection')
          const overlay = document?.getElementById('modalContainer')
          if (overlay) {
            overlay.className = styles.overlayFinish
          }
          if (container) {
            container.className = styles.modalFinishAnimation
          }
          setTimeout(() => {
            setModal({ ...initialStateModal, successModalIsOpen: true })
            navigate('/thank-you', {
              state: { backgroundLocation: location },
            })
          }, 400)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const closeFnc = () => {
    const container = document?.getElementById('modalSection')
    const overlay = document?.getElementById('modalContainer')
    if (overlay) {
      overlay.className = styles.overlayFinish
    }
    if (container) {
      container.className = styles.modalFinishAnimation
    }
    setTimeout(() => {
      setModal(initialStateModal)
    }, 400)
  }

  return <>
    <div onClick={closeFnc} id='modalContainer' className={styles.overlay}>
      <div id='modalSection' onClick={e => e.stopPropagation()} className={styles.modalSection} >
        <div className={styles.border}>
          <div className={styles.background} >
            <div className='filter' />
            <h2>Contact us</h2>
          </div>
          <form
            className={styles.form}
            onSubmit={onSubmit}
            onChange={onChange}
          >
            <button type='button' className={styles.closeBtn} onClick={closeFnc}>
              <Close />
            </button>

            {inputsName.map(el =>
              <label key={el.name}>{el.label}
                <input
                  className={`${error && hasError[el?.name] ? styles.errorInput : ''}`} id={el?.name} name={el.name}
                />
                <span className={styles.error}>{hasError[el?.name]}</span>
              </label>,
            )}

            <div className={styles.radiogrup}>
              <label className={`${data?.contact === 'phone' ? styles.radioNoActive : styles.radio}`}>
                <input defaultChecked={data.contact === 'mail'} type='radio' name='contact' value='mail' />
                <div className='radioBtn' />
                <span className={styles.label}>email</span>
              </label>

              <label className={`${data?.contact === 'mail' ? styles.radioNoActive : styles.radio}`}>
                <input defaultChecked={data.contact === 'phone'} type='radio' name='contact' value='phone' />
                <div className='radioBtn' />
                <span className={styles.label}>phone number</span>
              </label>
            </div>
            <label>
              {data?.contact === 'mail'
                ? <>
                  <input className={`${error && hasError?.email ? styles.errorInput : ''}`}
                    id='email' name='email'
                  />
                  <span className={styles.error}>{hasError?.email}</span>
                </>
                : <>
                  <input className={`${error && hasError?.phone ? styles.errorInput : ''}`}
                    id='phone' name='phone'
                  />
                  <span className={styles.error}>{hasError?.phone}</span>
                </>
              }
            </label>
            <label>request
              <textarea id='comment' name='comment' />
            </label>
            <button
              className={styles.modalBtn}
              onClick={onSubmit}
              disabled={loading}
              type='submit'
            >
               submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </>
}
export default ModalHero