/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
//components
import { ListofJobsContext } from '../../App'
//styles and icons
import { ReactComponent as BackOrange } from '../../image/backOrange.svg'
import { jobs, aboutJobs, HOME, JOBS } from '../../utils/data'
import styles from './index.module.css'

const ListofJobs = ({ className, btnIsVisible = true }) => {

  const nav = useNavigate()
  const { pathname } = useLocation()
  const { setListOfJobs, listOfJobs } = useContext(ListofJobsContext)
  const width = window.innerWidth

  const onChange = item => {

    setListOfJobs({
      name: item.name,
      open: aboutJobs[item.name]?.category,
      about: !aboutJobs[item.name]?.category,
      flex: 'column',
    })

    nav(`/services/${item.slug}`)
  }

  useEffect(() => {
    window.scroll({
      top: getValue(),
      behavior: 'smooth',
    })
  }, [listOfJobs.name])

  const getValue = () => {
    if(!listOfJobs?.name) return 0
    if (width > 768) return 80
    if (width > 600) return 430
    if (width >= 500) return 560
    if (width >= 480) return 540
    return 490
  }
  const isVisibleBtn = pathname !== HOME && !listOfJobs.about && !listOfJobs.open
  const classLi = el => `${listOfJobs.name === el?.name ? 'active' : ''} li`
  const stylesContainer = {
    width: listOfJobs.flex === 'row' ? '100%' : '',
    margin: listOfJobs.flex === 'row' ? '0 auto 100px' : '0',

    maxWidth: width < 768 && width > 700 && !listOfJobs.name ? 'unset'
      : width > 768 && listOfJobs.name ? '290px' : 'unset',
  }

  return (<div className={className} style={{ width: listOfJobs.flex === 'row' ? '100%' : ' ' }}>

    {btnIsVisible && <div className={styles.homeBtn}>
      {isVisibleBtn &&
        <button id='btnBack' onClick={() => nav(HOME)}>
          <BackOrange style={{ marginRight: '5px' }} />back
        </button>}
    </div>
    }

    <div
      className={`${styles.listContainer} ${btnIsVisible ? '' : styles.isMenuContainer}`}
      id='listjobs' style={stylesContainer}
    >

      <div style={{
        flexDirection: listOfJobs.flex, width: '100%',
        gap: listOfJobs.flex === 'column' ? '40px' : '25%',
      }}
      className={`${styles.main} ${btnIsVisible ? '' : styles.isMenu}`}
      >
        <div style={{
          display: 'flex', gap:  listOfJobs.flex === 'column', position: 'relative', top: '60px',
          flexDirection:  'column',
        }}
        >
          <h3 className={styles.title}>Garden Maintenance</h3>
          {jobs.map((el, i) => {

            return <div key={i} className={styles.listJob} id='titleJob' >
              <ul className={styles.titleBlock}>
                {el.filter(el => el.type !== 'L').map(el => {

                  return <li key={el?.name} className={classLi(el)} onClick={() => onChange(el)}>
                    <button className={styles.link} >{el.name}</button>
                    <div className={styles.line} />
                  </li>
                })}
              </ul>
            </div>
          })}
        </div>
        <div style={{ position: 'relative', top: '60px' }}>
          <h3 className={styles.title}>Landscaping</h3>
          <div style={{ width: '100%', flexDirection: 'column' }}
            className={`${styles.main} ${btnIsVisible ? '' : styles.isMenu}`}
          >
            {jobs.map((el, i) => {

              return <div key={i} className={styles.listJob} id='titleJob' >
                <ul className={styles.titleBlock}>
                  {el.filter(el => el.type === 'L').map(el => {

                    return <li key={el?.name} className={classLi(el)} onClick={() => onChange(el)}>
                      <button className={styles.link} >{el.name}</button>
                      <div className={styles.line} />
                    </li>
                  })}
                </ul>
              </div>
            })}

          </div>
        </div>

      </div>

    </div>

  </div>
  )
}
export default ListofJobs