import React from 'react'
import { useContext } from 'react'
import { ListofJobsContext } from '../../../App'
import { aboutJobs } from '../../../utils/data'
import styles from '../Jobs/index.module.css'

const CategoryJobs = () => {
  const { listOfJobs } = useContext(ListofJobsContext)
  const data = aboutJobs[listOfJobs.name]

  return <div className={styles.container}>
    {data?.categoryContent?.map((el, i) => {
      return(
        <div key={i} className={`${styles.categoryContainer} ${styles.categoryContainerSec}`}>
          <h2 style={{ textAlign : 'left' }} className={styles.title}>{el?.title}</h2>
          <div className={styles.categoryFlex} style={{ flexDirection: !!el?.doubleImg ? 'column' : 'row' }}>
            <div className={styles.info}>
              <span style={{ paddingBottom: '15px' }}>{el.text}</span>
              {!!el?.subtitle && <h3 className={styles.subtitle}>{el?.subtitle}</h3>}

              {/* list info */}
              {!!el?.list && el?.list?.map((info, i) => {
                return <>
                  <span key={i}><div className='point' />{'  '}{info}</span><br />
                </>
              })}

              {/* price bottom */}
              {el?.price && el?.price}
            </div>

            {/* 1 img in category */}
            {!!el?.img && <img key={el?.img} src={el?.img} alt='img' />}

            {/* 2 img category */}
            {!!el?.doubleImg &&
              <div className={styles.categoryDobleImages}>
                {el?.doubleImg?.map(srcI => {
                  return <img className={styles.categoryDobleImagesImg} key={srcI} src={srcI} alt='img' />
                })}</div>}
          </div>
        </div>
      )
    })}
    {!!data?.lastImg && <img className={styles.lastImg} alt='i' src={data?.lastImg} />}
  </div>
}

export default CategoryJobs
