/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { GalleryContext } from '../../../App'
//data and styles
import { project, isMobile } from '../../../utils/data'
import styles from './index.module.css'

const GalleryList = ({ setVisited, setTime }) => {
  const { projectName , setProjectName } = useContext(GalleryContext)

  const onChange = name => {
    setProjectName({ name })
    window.scrollTo({
      top: 200,
      behavior: 'smooth',
    })
    setVisited((prevState) => {
      const isInside = prevState.includes(name)
      setTime((isMobile && isInside) ? 2500 : (isInside ? 2000 : (isMobile ? 5000 : 4000)))
      return isInside ? [...prevState] : [...prevState, name]
    })
  }

  const getStylesContainer = {
    width: !projectName.name ? '100%' : 'none' && projectName.name ? '200px' : 'none',
    display: window.innerWidth < 900 && !!projectName.name ? 'none' : 'flex',
  }

  const isVisible = { display: window.innerWidth < 900 && !!projectName.name ? 'none' : 'flex' }
  const direction = { flexDirection: projectName.name, width: '100%' }
  const isActive = (el) => projectName.name === el?.name ? 'active' : ''
  const listStyles = {
    minWidth: '200px',
    maxWidth: !projectName.name ? '1240px' : '100%',
    display: !projectName.name ? 'flex' : 'block',
    justifyContent: !projectName.name && 'center',
    flexWrap:!projectName.name ? 'wrap' : 'nowrap',
  }

  return(
    <div style={getStylesContainer}>
      <div className={styles.listContainer} id='listjobs' style={isVisible}>
        <div className={styles.main} style={direction}>
          <ul className={styles.titleBlock} style={listStyles}>
            {project.map(el => {
              return <li key={el?.name} onClick={() => {onChange(el?.name)}} className={isActive(el)}>
                <button className={styles.link}>
                  {!projectName?.name && <img className={styles.image} src={el.img} /> }
                  {projectName.name
                    ? <span className={styles.mainTitle}>{el.name}</span>
                    : <span className={styles.title}>{el.name}</span>
                  }
                </button>
                {!!projectName.name && <div className={styles.line} />}
              </li>
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
export default GalleryList