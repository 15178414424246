import Before1 from '../../../image/Gallery/CrouchHill/Before1.jpg'
import Before2 from '../../../image/Gallery/CrouchHill/Before2.jpg'
import Before3 from '../../../image/Gallery/CrouchHill/Before3.jpg'
import Before4 from '../../../image/Gallery/CrouchHill/Before4.jpg'
import Project1 from '../../../image/Gallery/CrouchHill/Project1.jpg'
import Project2 from '../../../image/Gallery/CrouchHill/Project2.jpg'
import moment1 from '../../../image/Gallery/CrouchHill/Workmoment1.jpg'
import moment2 from '../../../image/Gallery/CrouchHill/Workmoment2.jpeg'
import moment3 from '../../../image/Gallery/CrouchHill/Workmoment3.jpeg'
import moment4 from '../../../image/Gallery/CrouchHill/Workmoment4.jpeg'
import moment5 from '../../../image/Gallery/CrouchHill/Workmoment5.jpeg'
import moment6 from '../../../image/Gallery/CrouchHill/Workmoment6.jpeg'
import moment7 from '../../../image/Gallery/CrouchHill/Workmoment7.jpg'
import moment8 from '../../../image/Gallery/CrouchHill/Workmoment8.jpg'
import moment9 from '../../../image/Gallery/CrouchHill/Workmoment9.jpg'
import moment10 from '../../../image/Gallery/CrouchHill/Workmoment10.jpg'
import moment11 from '../../../image/Gallery/CrouchHill/Workmoment11.jpg'
import moment12 from '../../../image/Gallery/CrouchHill/Workmoment12.jpg'
import moment13 from '../../../image/Gallery/CrouchHill/Workmoment13.jpg'
import moment14 from '../../../image/Gallery/CrouchHill/Workmoment14.jpg'
import moment15 from '../../../image/Gallery/CrouchHill/Workmoment15.jpg'
import Read1 from '../../../image/Gallery/CrouchHill/Read1.jpg'
import Read2 from '../../../image/Gallery/CrouchHill/Read2.jpg'
import Read3 from '../../../image/Gallery/CrouchHill/Read3.jpg'
import Read4 from '../../../image/Gallery/CrouchHill/Read4.jpg'
import Read5 from '../../../image/Gallery/CrouchHill/Read5.jpg'
import Read6 from '../../../image/Gallery/CrouchHill/Read6.jpg'
import Read7 from '../../../image/Gallery/CrouchHill/Read7.jpg'
import Read8 from '../../../image/Gallery/CrouchHill/Read8.jpg'
import Read9 from '../../../image/Gallery/CrouchHill/Read9.jpg'
import Read10 from '../../../image/Gallery/CrouchHill/Read10.jpg'
import Ready11 from '../../../image/Gallery/CrouchHill/Ready11.jpg'

import eBefore1 from '../../../image/Gallery/EastFinchley/Before1.jpg'
import eBefore2 from '../../../image/Gallery/EastFinchley/Before2.jpg'
import eBefore3 from '../../../image/Gallery/EastFinchley/Before3.jpg'
import eProject1 from '../../../image/Gallery/EastFinchley/Project1.jpg'
import eProject2 from '../../../image/Gallery/EastFinchley/Project2.jpg'
import eProject3 from '../../../image/Gallery/EastFinchley/Project3.jpg'
import eProject4 from '../../../image/Gallery/EastFinchley/Project4.jpg'
import eProject5 from '../../../image/Gallery/EastFinchley/Project5.jpg'
import eReady1 from '../../../image/Gallery/EastFinchley/Ready1.jpg'
import eReady2 from '../../../image/Gallery/EastFinchley/Ready2.jpg'
import eReady3 from '../../../image/Gallery/EastFinchley/Ready3.jpg'
import eReady4 from '../../../image/Gallery/EastFinchley/Ready4.jpg'
import eReady5 from '../../../image/Gallery/EastFinchley/Ready5.jpg'
import eReady6 from '../../../image/Gallery/EastFinchley/Ready6.jpg'
import eReady7 from '../../../image/Gallery/EastFinchley/Ready7.jpg'
import eReady8 from '../../../image/Gallery/EastFinchley/Ready8.jpg'
import eReady9 from '../../../image/Gallery/EastFinchley/Ready9.jpg'
import eReady10 from '../../../image/Gallery/EastFinchley/Ready10.jpg'
import eReady11 from '../../../image/Gallery/EastFinchley/Ready11.jpg'
import eReady12 from '../../../image/Gallery/EastFinchley/Ready12.jpg'

import hProject1 from '../../../image/Gallery/HadleyWood/Project1.jpg'
import hProject2 from '../../../image/Gallery/HadleyWood/Project2.jpg'
import hProject3 from '../../../image/Gallery/HadleyWood/Project3.jpg'
import hProject4 from '../../../image/Gallery/HadleyWood/Project4.jpg'
import hProject5 from '../../../image/Gallery/HadleyWood/Project5.jpg'
import hProject6 from '../../../image/Gallery/HadleyWood/Project6.jpg'
import hProject7 from '../../../image/Gallery/HadleyWood/Project7.jpg'
import hProject8 from '../../../image/Gallery/HadleyWood/Project8.jpg'
import hProject9 from '../../../image/Gallery/HadleyWood/Project9.jpg'
import hReady1 from '../../../image/Gallery/HadleyWood/Ready1.jpg'
import hReady2 from '../../../image/Gallery/HadleyWood/Ready2.jpg'
import hReady3 from '../../../image/Gallery/HadleyWood/Ready3.jpg'
import hReady4 from '../../../image/Gallery/HadleyWood/Ready4.jpg'
import hReady5 from '../../../image/Gallery/HadleyWood/Ready5.jpg'
import hReady6 from '../../../image/Gallery/HadleyWood/Ready6.jpg'
import hReady7 from '../../../image/Gallery/HadleyWood/Ready7.jpg'
import hReady8 from '../../../image/Gallery/HadleyWood/Ready8.jpg'
import hReady9 from '../../../image/Gallery/HadleyWood/Ready9.jpg'
import hReady10 from '../../../image/Gallery/HadleyWood/Ready10.jpg'
import hReady11 from '../../../image/Gallery/HadleyWood/Ready11.jpg'
import hReady12 from '../../../image/Gallery/HadleyWood/Ready12.jpg'
import hReady13 from '../../../image/Gallery/HadleyWood/Ready13.jpg'

import lBefore1 from '../../../image/Gallery/Loughton/Before1.jpg'
import lBefore2 from '../../../image/Gallery/Loughton/Before2.jpg'
import lProjectPic1 from '../../../image/Gallery/Loughton/ProjectPic1.jpg'
import lProjectPic2 from '../../../image/Gallery/Loughton/ProjectPic2.jpg'
import lProjectPic3 from '../../../image/Gallery/Loughton/ProjectPic3.jpg'
import lProjectPic4 from '../../../image/Gallery/Loughton/ProjectPic4.jpg'
import lProjectPic5 from '../../../image/Gallery/Loughton/ProjectPic5.jpg'
import lProjectPic6 from '../../../image/Gallery/Loughton/ProjectPic6.jpg'
import lReady1 from '../../../image/Gallery/Loughton/Ready1.jpg'
import lReady2 from '../../../image/Gallery/Loughton/Ready2.jpg'
import lReady3 from '../../../image/Gallery/Loughton/Ready3.jpg'
import lReady4 from '../../../image/Gallery/Loughton/Ready4.jpg'
import lReady5 from '../../../image/Gallery/Loughton/Ready5.jpg'
import lmoment2 from '../../../image/Gallery/Loughton/Workmoment2.jpg'
import lmoment from '../../../image/Gallery/Loughton/Workmoment.jpg'

import mBefore1 from '../../../image/Gallery/MuswellHill/Before1.jpg'
import mBefore2 from '../../../image/Gallery/MuswellHill/Before2.jpg'
import mBefore3 from '../../../image/Gallery/MuswellHill/Before3.jpg'
import mBefore4 from '../../../image/Gallery/MuswellHill/Before4.jpg'
import mProject1 from '../../../image/Gallery/MuswellHill/Project1.jpg'
import mProject2 from '../../../image/Gallery/MuswellHill/Project2.jpg'
import mProject3 from '../../../image/Gallery/MuswellHill/Project3.jpg'
import mProject4 from '../../../image/Gallery/MuswellHill/Project4.jpg'
import mProject5 from '../../../image/Gallery/MuswellHill/Project5.jpg'
import mProject6 from '../../../image/Gallery/MuswellHill/Project6.jpg'
import mProject7 from '../../../image/Gallery/MuswellHill/Project7.jpg'
import mProject8 from '../../../image/Gallery/MuswellHill/Project8.jpg'
import mProject9 from '../../../image/Gallery/MuswellHill/Project9.jpg'
import mProject10 from '../../../image/Gallery/MuswellHill/Project10.jpg'
import mMoment1 from '../../../image/Gallery/MuswellHill/Workmoment1.jpg'
import mMoment2 from '../../../image/Gallery/MuswellHill/Workmoment2.jpg'
import mMoment3 from '../../../image/Gallery/MuswellHill/Workmoment3.jpg'
import mMoment4 from '../../../image/Gallery/MuswellHill/Workmoment4.jpg'
import mMoment5 from '../../../image/Gallery/MuswellHill/Workmoment5.jpg'
import mMoment6 from '../../../image/Gallery/MuswellHill/Workmoment6.jpg'
import mMoment7 from '../../../image/Gallery/MuswellHill/Workmoment7.jpg'
import mMoment8 from '../../../image/Gallery/MuswellHill/Workmoment8.jpg'
import mMoment9 from '../../../image/Gallery/MuswellHill/Workmoment9.jpg'
import mMoment10 from '../../../image/Gallery/MuswellHill/Workmoment10.jpg'
import mMoment11 from '../../../image/Gallery/MuswellHill/Workmoment11.jpg'
import mMoment12 from '../../../image/Gallery/MuswellHill/Workmoment12.jpg'
import mMoment13 from '../../../image/Gallery/MuswellHill/Workmoment13.jpg'
import mMoment14 from '../../../image/Gallery/MuswellHill/Workmoment14.jpg'
import mMoment15 from '../../../image/Gallery/MuswellHill/Workmoment15.jpg'
import mMoment16 from '../../../image/Gallery/MuswellHill/Workmoment16.jpg'
import mMoment17 from '../../../image/Gallery/MuswellHill/Workmoment17.jpg'
import mMoment18 from '../../../image/Gallery/MuswellHill/Workmoment18.jpg'
import mMoment19 from '../../../image/Gallery/MuswellHill/Workmoment19.jpg'
import mMoment20 from '../../../image/Gallery/MuswellHill/Workmoment20.jpg'
import mMoment21 from '../../../image/Gallery/MuswellHill/Workmoment21.jpg'
import mMoment22 from '../../../image/Gallery/MuswellHill/Workmoment22.jpg'
import mMoment23 from '../../../image/Gallery/MuswellHill/Workmoment23.jpg'
import mMoment24 from '../../../image/Gallery/MuswellHill/Workmoment24.jpg'
import mMoment25 from '../../../image/Gallery/MuswellHill/Workmoment25.jpg'

import Planting1 from '../../../image/Gallery/Planting/01fba2eb.jpg'
import Planting2 from '../../../image/Gallery/Planting/0c4eb163.jpg'
import Planting3 from '../../../image/Gallery/Planting/16cbc580.jpg'
import Planting4 from '../../../image/Gallery/Planting/272141b3.jpg'
import Planting5 from '../../../image/Gallery/Planting/27987b97.jpg'
import Planting6 from '../../../image/Gallery/Planting/5f6632d9.jpg'
import Planting7 from '../../../image/Gallery/Planting/6b17713c.jpg'
import Planting8 from '../../../image/Gallery/Planting/73fb2448.jpg'
import Planting9 from '../../../image/Gallery/Planting/b95934e6.jpg'
import Planting10 from '../../../image/Gallery/Planting/d6117bd6.jpg'
import Planting11 from '../../../image/Gallery/Planting/ef24d3b2.jpg'
import Planting12 from '../../../image/Gallery/Planting/xcvbnm.jpg'
import Planting13 from '../../../image/Gallery/Planting/liugty63365.jpg'
import Planting14 from '../../../image/Gallery/Planting/465ygtf56.jpg'
import Planting15 from '../../../image/Gallery/Planting/d6117bd67.jpg'
import Planting16 from '../../../image/Gallery/Planting/f75987y6.jpg'

import rProject1 from '../../../image/Gallery/Redbridge/Project1.jpg'
import rProject2 from '../../../image/Gallery/Redbridge/Project2.jpg'
import rProject3 from '../../../image/Gallery/Redbridge/Project3.jpg'
import rProject4 from '../../../image/Gallery/Redbridge/Project4.jpg'
import rReady1 from '../../../image/Gallery/Redbridge/Ready1.jpg'
import rReady2 from '../../../image/Gallery/Redbridge/Ready2.jpg'
import rReady3 from '../../../image/Gallery/Redbridge/Ready3.jpg'
import rReady4 from '../../../image/Gallery/Redbridge/Ready4.jpg'
import rMoment1 from '../../../image/Gallery/Redbridge/Workmoment1.jpg'
import rMoment2 from '../../../image/Gallery/Redbridge/Workmoment2.jpg'
import rMoment3 from '../../../image/Gallery/Redbridge/Workmoment3.jpg'
import rMoment4 from '../../../image/Gallery/Redbridge/Workmoment4.jpg'
import rMoment5 from '../../../image/Gallery/Redbridge/Workmoment5.jpg'
import rMoment6 from '../../../image/Gallery/Redbridge/Workmoment6.jpg'

import wBefore1 from '../../../image/Gallery/WoodfordGreen/Before1.jpg'
import wBefore2 from '../../../image/Gallery/WoodfordGreen/Before2.jpg'
import wBefore3 from '../../../image/Gallery/WoodfordGreen/Before3.jpg'
import wProject1 from '../../../image/Gallery/WoodfordGreen/Project1.jpg'
import wProject2 from '../../../image/Gallery/WoodfordGreen/Project2.jpg'
import wProject3 from '../../../image/Gallery/WoodfordGreen/Project3.jpg'
import wProject4 from '../../../image/Gallery/WoodfordGreen/Project4.jpg'
import wProject5 from '../../../image/Gallery/WoodfordGreen/Project5.jpg'
import wReady1 from '../../../image/Gallery/WoodfordGreen/Ready1.jpg'
import wReady2 from '../../../image/Gallery/WoodfordGreen/Ready2.jpg'
import wReady3 from '../../../image/Gallery/WoodfordGreen/Ready3.jpg'
import wReady4 from '../../../image/Gallery/WoodfordGreen/Ready4.jpg'
import wReady5 from '../../../image/Gallery/WoodfordGreen/Ready5.jpg'
import wReady6 from '../../../image/Gallery/WoodfordGreen/Ready6.jpg'
import wReady7 from '../../../image/Gallery/WoodfordGreen/Ready7.jpg'
import wReady8 from '../../../image/Gallery/WoodfordGreen/Ready8.jpg'
import wMoment1 from '../../../image/Gallery/WoodfordGreen/Workmoment1.jpg'
import wMoment2 from '../../../image/Gallery/WoodfordGreen/Workmoment2.jpg'
import wMoment3 from '../../../image/Gallery/WoodfordGreen/Workmoment3.jpg'
import wMoment4 from '../../../image/Gallery/WoodfordGreen/Workmoment4.jpg'
import wMoment5 from '../../../image/Gallery/WoodfordGreen/Workmoment5.jpg'
import wMoment6 from '../../../image/Gallery/WoodfordGreen/Workmoment6.jpg'
import wMoment7 from '../../../image/Gallery/WoodfordGreen/Workmoment7.jpg'
import wMoment8 from '../../../image/Gallery/WoodfordGreen/Workmoment8.jpg'
import wMoment9 from '../../../image/Gallery/WoodfordGreen/Workmoment9.jpg'

export const projects = {
  'Woodford Green': [
    { img: wBefore1 },
    { img: wBefore2 },
    { img: wBefore3 },
    { img: wMoment1 },
    { img: wMoment2 },
    { img: wMoment3 },
    { img: wMoment4 },
    { img: wMoment5 },
    { img: wMoment6 },
    { img: wMoment7 },
    { img: wMoment8 },
    { img: wMoment9 },
    { img: wProject1 },
    { img: wProject2 },
    { img: wProject3 },
    { img: wProject4 },
    { img: wProject5 },
    { img: wReady1 },
    { img: wReady2 },
    { img: wReady3 },
    { img: wReady4 },
    { img: wReady5 },
    { img: wReady6 },
    { img: wReady7 },
    { img: wReady8 },
  ],
  'Redbridge': [
    { img: rMoment1 },
    { img: rMoment2 },
    { img: rMoment3 },
    { img: rMoment4 },
    { img: rMoment5 },
    { img: rMoment6 },
    { img: rProject1 },
    { img: rProject2 },
    { img: rProject3 },
    { img: rProject4 },
    { img: rReady1 },
    { img: rReady2 },
    { img: rReady3 },
    { img: rReady4 },
  ],
  'Planting': [
    { img: Planting1 },
    { img: Planting2 },
    { img: Planting3 },
    { img: Planting4 },
    { img: Planting5 },
    { img: Planting6 },
    { img: Planting7 },
    { img: Planting8 },
    { img: Planting9 },
    { img: Planting10 },
    { img: Planting11 },
    { img: Planting12 },
    { img: Planting13 },
    { img: Planting14 },
    { img: Planting15 },
    { img: Planting16 },
  ],
  'Muswell Hill':  [
    { img: mBefore1 },
    { img: mBefore2 },
    { img: mBefore3 },
    { img: mBefore4 },
    { img: mMoment1 },
    { img: mMoment2 },
    { img: mMoment3 },
    { img: mMoment4 },
    { img: mMoment5 },
    { img: mMoment6 },
    { img: mMoment7 },
    { img: mMoment8 },
    { img: mMoment9 },
    { img: mMoment10 },
    { img: mMoment11 },
    { img: mMoment12 },
    { img: mMoment13 },
    { img: mMoment14 },
    { img: mMoment15 },
    { img: mMoment16 },
    { img: mMoment17 },
    { img: mMoment18 },
    { img: mMoment19 },
    { img: mMoment20 },
    { img: mMoment21 },
    { img: mMoment22 },
    { img: mMoment23 },
    { img: mMoment24 },
    { img: mMoment25 },
    { img: mProject1 },
    { img: mProject2 },
    { img: mProject3 },
    { img: mProject4 },
    { img: mProject5 },
    { img: mProject6 },
    { img: mProject7 },
    { img: mProject8 },
    { img: mProject9 },
    { img: mProject10 },
    { img: mMoment20 },
    { img: mMoment21 },
    { img: mMoment22 },
    { img: mMoment23 },
    { img: mMoment24 },
    { img: mMoment25 },
    { img: mProject1 },
    { img: mProject2 },
  ],
  'Loughton': [
    { img: lBefore1 },
    { img: lBefore2 },
    { img: lProjectPic1 },
    { img: lProjectPic2 },
    { img: lProjectPic3 },
    { img: lProjectPic4 },
    { img: lProjectPic5 },
    { img: lProjectPic6 },
    { img: lReady1 },
    { img: lReady2 },
    { img: lReady3 },
    { img: lReady4 },
    { img: lReady5 },
    { img: lmoment },
    { img: lmoment2 },
  ],
  'Hadley Wood': [
    { img: hProject1 },
    { img: hProject2 },
    { img: hProject3 },
    { img: hProject4 },
    { img: hProject5 },
    { img: hProject6 },
    { img: hProject7 },
    { img: hProject8 },
    { img: hProject9 },
    { img: hReady1 },
    { img: hReady2 },
    { img: hReady3 },
    { img: hReady4 },
    { img: hReady5 },
    { img: hReady6 },
    { img: hReady7 },
    { img: hReady8 },
    { img: hReady9 },
    { img: hReady10 },
    { img: hReady11 },
    { img: hReady12 },
    { img: hReady13 },
  ],
  'East Finchley': [
    { img: eBefore1 },
    { img: eBefore2 },
    { img: eBefore3 },
    { img: eProject1 },
    { img: eProject2 },
    { img: eProject3 },
    { img: eProject4 },
    { img: eProject5 },
    { img: eReady1 },
    { img: eReady2 },
    { img: eReady3 },
    { img: eReady4 },
    { img: eReady5 },
    { img: eReady6 },
    { img: eReady7 },
    { img: eReady8 },
    { img: eReady9 },
    { img: eReady10 },
    { img: eReady11 },
    { img: eReady12 },
  ],
  'Crouch Hill':  [
    { img: Before1 },
    { img: Before2 },
    { img: Before3 },
    { img: Before4 },
    { img: Project1 },
    { img: Project2 },
    { img: moment1 },
    { img: moment2 },
    { img: moment3 },
    { img: moment4 },
    { img: moment5 },
    { img: moment6 },
    { img: moment7 },
    { img: moment8 },
    { img: moment9 },
    { img: moment10 },
    { img: moment11 },
    { img: moment12 },
    { img: moment13 },
    { img: moment14 },
    { img: moment15 },
    { img: Read1 },
    { img: Read2 },
    { img: Read3 },
    { img: Read4 },
    { img: Read5 },
    { img: Read6 },
    { img: Read7 },
    { img: Read8 },
    { img: Read9 },
    { img: Read10 },
    { img: Ready11 },
  ],
}