import React, { createContext, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
//components
import SuccessModal from './components/ModalHero/successModal'
import MobileMenu from './components/Header/MobileMenu'
import NavList from './components/Header/NavList'
import { GalleryPage } from './pages/GalleryPage'
import Jobs from './components/ListofJobs/Jobs'
import ModalHero from './components/ModalHero'
import ContactUs from './components/Contact'
import { HomePage } from './pages/HomePage'
import AboutUs from './components/AboutUs'
import Footer from './components/Footer'
import Header from './components/Header'
//data
import { initialStateGallery, initialStateListOfJobs, initialStateModal } from './utils/data'
//styles
import './App.css'

export const ListofJobsContext = createContext(null)
export const PageContext = createContext(null)
export const GalleryContext = createContext(null)
export const ModalContext = createContext(null)

function App() {
  const [page, setPage] = useState()
  const [listOfJobs, setListOfJobs] = useState(initialStateListOfJobs)
  const [projectName, setProjectName] = useState(initialStateGallery)
  const [modal, setModal] = useState(initialStateModal)

  const location = useLocation()
  const state = location.state
  const backgroundLocation = state && state.backgroundLocation

  return <PageContext.Provider value={{ setPage, page }}>
    <ListofJobsContext.Provider value={{ setListOfJobs, listOfJobs }}>
      <GalleryContext.Provider value={{ setProjectName, projectName }}>
        <ModalContext.Provider value={{ setModal, modal }}>
          {modal?.successModalIsOpen && <SuccessModal /> }
          {modal?.mobileMenuIsOpen && <MobileMenu />}
          {modal?.contactUsIsOpen && <ModalHero />}
          <Header />
          <NavList />
          <Routes location={backgroundLocation || location}>
            <Route path='/' element={<HomePage />} />
            <Route path='/services' element={<><Jobs /><ContactUs /></>} />
            <Route path='/services/:category' element={<><Jobs /><ContactUs /></>} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/gallery' element={<GalleryPage />} />
            <Route path='/contact' element={<ContactUs />} />
          </Routes>
          {backgroundLocation && (
            <Routes>
              <Route path='/thank-you' element={<SuccessModal />} />
            </Routes>
          )}
          <Footer />
        </ModalContext.Provider>
      </GalleryContext.Provider>
    </ListofJobsContext.Provider>
  </PageContext.Provider>
}

export default App
