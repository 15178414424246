/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import al1 from '../image/readMore/artificialLawn/al1.jpeg'
import al2 from '../image/readMore/artificialLawn/al2.jpeg'
import al3 from '../image/readMore/artificialLawn/al3.jpeg'
import al4 from '../image/readMore/artificialLawn/al4.jpeg'
import gravelPath from '../image/readMore/compositeDecking/gravelPath.jpeg'
import foggyWhart from '../image/readMore/compositeDecking/foggyWhart.jpeg'
import islandMist from '../image/readMore/compositeDecking/islandMist.jpeg'
import clamShell from '../image/readMore/compositeDecking/clamShell.jpeg'
import calmWater from '../image/readMore/compositeDecking/calmWater.jpeg'
import spicedRum from '../image/readMore/compositeDecking/spicedRum.jpeg'
import saddle from '../image/readMore/compositeDecking/saddle.jpeg'
import rockyHarbour from '../image/readMore/compositeDecking/rockyHarbour.jpeg'
import lavaRock from '../image/readMore/compositeDecking/lavaRock.jpeg'
import toastedSand from '../image/readMore/compositeDecking/toastedSand.jpeg'
import tikkiTorch from '../image/readMore/compositeDecking/tikkiTorch.jpeg'
import dek1 from '../image/readMore/decking/dek1.jpeg'
import dek2 from '../image/readMore/decking/dek2.jpeg'
import dek3 from '../image/readMore/decking/dek3.jpeg'
import dek4 from '../image/readMore/decking/dek4.jpeg'
import Planting11 from '../image/Gallery/Planting/ef24d3b2.jpg'
import Planting12 from '../image/Gallery/Planting/xcvbnm.jpg'
import Planting13 from '../image/Gallery/Planting/liugty63365.jpg'
import Planting14 from '../image/Gallery/Planting/465ygtf56.jpg'
import Planting15 from '../image/Gallery/Planting/d6117bd67.jpg'
import Planting16 from '../image/Gallery/Planting/f75987y6.jpg'
import pf1 from '../image/readMore/panelFencing/pf1.jpeg'
import pf2 from '../image/readMore/panelFencing/pf2.jpeg'
import pf3 from '../image/readMore/panelFencing/pf3.jpeg'
import pf4 from '../image/readMore/panelFencing/pf4.jpeg'
import bf1 from '../image/readMore/battenFencing/bf1.jpeg'
import bf2 from '../image/readMore/battenFencing/bf2.jpeg'
import bf3 from '../image/readMore/battenFencing/bf3.jpeg'
import bf4 from '../image/readMore/battenFencing/bf4.jpeg'
import pav1 from '../image/readMore/paving/pav1.jpeg'
import pav2 from '../image/readMore/paving/pav2.jpeg'
import pav3 from '../image/readMore/paving/pav3.jpeg'
import pav4 from '../image/readMore/paving/pav4.jpeg'
import pav5 from '../image/readMore/paving/pav5.jpeg'
import pav6 from '../image/readMore/paving/pav6.jpeg'
import bp1 from '../image/readMore/blockPaving/bp1.jpeg'
import bp2 from '../image/readMore/blockPaving/bp2.jpeg'
import bp3 from '../image/readMore/blockPaving/bp3.jpeg'
import bp4 from '../image/readMore/blockPaving/bp4.jpeg'
import bp5 from '../image/readMore/blockPaving/bp5.jpeg'
import dr1 from '../image/readMore/drainage/dr1.jpeg'
import dr2 from '../image/readMore/drainage/dr2.jpeg'
import dr3 from '../image/readMore/drainage/dr3.jpeg'
import dr4 from '../image/readMore/drainage/dr4.jpeg'
import dr5 from '../image/readMore/drainage/dr5.jpeg'
import dr6 from '../image/readMore/drainage/dr6.jpeg'
import dr7 from '../image/readMore/drainage/dr7.jpeg'
import dr8 from '../image/readMore/drainage/dr8.jpeg'
import land1 from '../image/readMore/landscapeArchitect/land1.jpg'
import land2 from '../image/readMore/landscapeArchitect/land2.jpg'
import land3 from '../image/readMore/landscapeArchitect/land3.jpg'
import land4 from '../image/readMore/landscapeArchitect/land4.jpg'
import land5 from '../image/readMore/landscapeArchitect/land5.jpg'
import raised1 from '../image/readMore/raisedBeds/raised1.jpg'
import raised2 from '../image/readMore/raisedBeds/raised2.jpg'
import raised3 from '../image/readMore/raisedBeds/raised3.jpg'
import raised4 from '../image/readMore/raisedBeds/raised4.jpg'
import brickwall1 from '../image/readMore/brickwall/brickwall1.jpg'
import brickwall2 from '../image/readMore/brickwall/brickwall2.jpg'
import brickwall3 from '../image/readMore/brickwall/brickwall3.jpg'
import brickwall4 from '../image/readMore/brickwall/brickwall4.jpg'
import brickwall5 from '../image/readMore/brickwall/brickwall5.jpg'
import gat1 from '../image/readMore/gates/gat1.jpeg'
import gat2 from '../image/readMore/gates/gat2.jpeg'
import gat3 from '../image/readMore/gates/gat3.jpeg'
import gat4 from '../image/readMore/gates/gat4.jpeg'
import gat5 from '../image/readMore/gates/gat5.jpeg'
import lighting1 from '../image/readMore/lighting/lighting1.jpg'
import lighting2 from '../image/readMore/lighting/lighting2.jpg'
import lighting3 from '../image/readMore/lighting/lighting3.jpg'
import lighting4 from '../image/readMore/lighting/lighting4.jpg'
import lighting5 from '../image/readMore/lighting/lighting5.jpg'
import lighting6 from '../image/readMore/lighting/lighting6.jpg'
import summer1 from '../image/readMore/summer/summer1.jpg'
import summer2 from '../image/readMore/summer/summer2.jpg'
import summer3 from '../image/readMore/summer/summer3.jpg'
import summer4 from '../image/readMore/summer/summer4.jpg'
import Ready4 from '../image/Gallery/WoodfordGreen/Ready4.jpg'
import Ready1 from '../image/Gallery/EastFinchley/Ready1.jpg'
import Workmoment24 from '../image/Gallery/MuswellHill/Workmoment24.jpg'
import Read9 from '../image/Gallery/CrouchHill/Read9.jpg'
import Ready13 from '../image/Gallery/HadleyWood/Ready13.jpg'
import Ready2 from '../image/Gallery/Redbridge/Ready2.jpg'
import Ready from '../image/Gallery/Loughton/Ready1.jpg'
import Plan from '../image/Gallery/Planting/6b17713c.jpg'

import gardenClearance from '../image/gardenClearance.jpg'
import lawnCare from '../image/lawnCare.jpg'
import pressureWashing from '../image/pressureWashing.jpg'
import trimming from '../image/trimming.jpg'

export const jobs = [
  [
    { name: 'Planting Flowers and Trees', slug: 'planting-flowers-and-trees',type: 'L' },
    { name: 'Fence Installation', slug: 'fence-installation',type: 'L' },
    { name: 'Artificial Lawn', slug: 'artificial-lawn',type: 'L' },
    { name: 'Composite Decking', slug: 'composite-decking', type: 'L' },
    { name: 'Decking',slug: 'decking', type: 'L' },
    { name: 'Batten Fencing Softwood', slug: 'batten-fencing-softwood',type: 'L' },
    { name: 'Paving', slug: 'paving',type: 'L' },
    { name: 'Block Paving/Driveways',slug: 'block-paving-driveways', type: 'L' },
    { name: 'Drainage', slug: 'drainage', type: 'L' },
    { name: 'Gates' , slug: 'gates',type: 'L' },
    { name: 'Lighting', slug: 'lighting',type: 'L' },
  ],
  [
    { name: 'Summer Houses/ Garden Office', slug: 'summer-houses-garden-office', type: 'L' },
    { name: 'Raised Beds', slug: 'raised-beds',type: 'L' },
    { name: 'Brickwall', slug: 'brickwall',type: 'L' },
    { name: 'Landscape Architect', slug: 'landscape-architect', type: 'L' },
    { name: 'Garden Clearance', slug: 'garden-clearance' },
    { name: 'Lawn Care', slug: 'lawn-care' },
    { name: 'Pressure Washing', slug: 'pressure-washing' },
    { name: 'Bush and Tree Trimming', slug: 'bush-and-tree-trimming' },
  ],

]

export const aboutJobs = {
  'Artificial Lawn': {
    category: false,
    type: 'L',
    price: <>Average price per Sq.m:  <strong>£210</strong></>,
    description: <><strong style={{ color: '#FF8B1F' }}>Area Suitability</strong>
      – This product is suitable for schools, gardens, balconies. pool surrounds, play areas and indoor areas. <br />
      <strong style={{ color: '#FF8B1F', marginTop: '10px', display: 'inline-block' }}>Size Options</strong>
      – Available in 2m or 4m widths and up to 25m in length</>,

    imgFirst: <img width='100%' src={al1} />,
    imgSecondary: <img width='100%' src={al2} />,
    imgThird: <img width='100%' src={al3} />,
    imgFourth: <img width='100%' src={al4} />,
    list: [
      { title: 'Child Friendly', icon: undefined },
      { title: 'Pet Friendly', icon: undefined },
      { title: 'Looks great all Seasons', icon: undefined },
      { title: 'High UV Stabilisation', icon: undefined },
      { title: 'NO More Mowing', icon: undefined },
      { title: 'No More Muddy Feet / Paws', icon: undefined },
      { title: 'Natural Grass look', icon: undefined },
      { title: 'Cut to size and easy to shape', icon: undefined },
      { title: 'Certified to BS EN1177', icon: undefined },
      { title: 'Certified to BS7044', icon: undefined },
      { title: '10 Year Guarantee', icon: undefined },
      { title: 'No More Watering / Feeding', icon: undefined },
      { title: 'Certified to BS 4790', icon: undefined },
    ],
  },
  'Composite Decking': {
    type: 'L',
    category: false,
    description: <>
      <span>GROOVED EDGE BOARD</span> <br /><br />
      <strong>Actual dimensions:</strong> <br /><br />
      <div className='point' /> 82 in x 5.5 in x 12 ft (20 mm x 140 mm x 365 cm)<br /><br />
      <div className='point' /> 82 in x 5.5 in x 16 ft (20 mm x 140 mm x 487 cm)<br /><br />
      <div className='point' /> 82 in x 5.5 in x 20 ft (20 mm x 140 mm x 609 cm)<br /><br />
       Our grooved edge boards install with our Hidden Fastening System beneath the deck surface, leaving a tidy finish free of screw holes.
    </>,
    palette: [
      { title: 'Gravel Path', icon: gravelPath },
      { title: 'Foggy Wharf', icon: foggyWhart },
      { title: 'Island Mist', icon: islandMist },
      { title: 'Clam Shell', icon: clamShell },
      { title: 'Calm Water', icon: calmWater },
      { title: 'Spiced Rum', icon: spicedRum },
      { title: 'Saddle', icon: saddle },
      { title: 'Rocky Harbour', icon: rockyHarbour },
      { title: 'Lava Rock', icon: lavaRock },
      { title: 'Toasted Sand', icon: toastedSand },
      { title: 'Tikki Torch', icon: tikkiTorch },

    ],
    price: <span>Average Price per Sq.m: <strong>£400</strong></span>,
  },
  'Decking': {
    type: 'L',
    category: true,
    categoryContent: [
      {
        title:'Softwood Decking',
        list: ['Time of usage:  up to 10 years', 'Threated Frame:  6x2 timber'],
        price: <span>Average Price per Sq.m:<strong>£220</strong></span>,
        img: dek1,
      },
      {
        title: 'Hardwood Decking',
        list: ['Time of usage:  up to 25 years', 'Threated Frame:  6x2 timber'],
        price: <span>Average Price per Sq.m: <strong>£340</strong></span>,
        img: dek2,
      },
      {
        title: 'Softwood Decking Stained',
        list: ['Time of usage:  up to 15 years', 'Threated Frame:  6x2 timber'],
        price: <span>Average Price per Sq.m:<strong>£250</strong></span>,
        img: dek3,
      },
      {
        title: 'Decking Maintenance',
        list: ['Jet Wash of the Deck', 'Anti Slippery Decking Stain', 'Painting', 'Minimum Charge 10m2'],
        price: <span>Average Price per Sq.m:<strong>£60</strong></span>,
        img: dek4,
      },
    ],
  },
  'Lawn Care': {
    isHiddenTitle: true,
    category: false,
    description:<div className='jobContentDescription'><span className='pageDesc'><strong>Why Lawn Mowing is Important</strong> <br /><br />

    Keeping your lawn neatly mowed isn’t just about appearance — it’s about creating a healthy and enjoyable outdoor space. Regular mowing helps grass grow evenly, making your lawn look smooth and clean. It also keeps weeds and pests under control, improving the overall health of your garden.<br /><br />
    A well-maintained lawn adds value to your property and creates a pleasant space for family gatherings, barbecues, or simply relaxing outdoors. A freshly cut lawn shows you care for your home and makes a great impression on visitors or neighbours.<br /><br />
      Investing in professional lawn mowing saves you time and effort while ensuring your garden always looks its best. It’s an easy way to enjoy a green, healthy, and beautiful outdoor space year-round.</span><div className='descImg' ><img src={lawnCare} /></div></div>,
    priceTitle: <span className='priceTitle'>Our prices for two persons:</span>,
    listSecondary: [
      { title: 'First hour - 80£', icon: undefined },
      { title: 'Subsequent hour - 60£', icon: undefined },
      { title: '4 hours order - 233 (10% discount)', icon: undefined },
      { title: '8 hours order - 400 (20% discount)', icon: undefined },
    ],
    attention: <span className='attentText'><strong>Attention: </strong> you can save money just ordering other our services together like Garden Clearance, Pressure Washing or Bush and Tree Trimming, to receive up to 20% discount!</span>,
  },
  'Garden Clearance': {
    isHiddenTitle: true,
    category: false,
    description: <div className='jobContentDescription'>
      <span className='pageDesc'>  <strong>Garden Cleaning is so easy with us </strong> <br /><br />
      A clean and tidy garden is not only more beautiful but also healthier. Regular garden cleaning removes fallen leaves, weeds, and debris that can attract pests or harm your plants. It helps your garden grow better and stay vibrant all year round.<br /><br />
      A well-maintained garden creates a welcoming space to relax or entertain guests. It adds value to your home and makes it more enjoyable to spend time outdoors. Plus, cleaning up your garden prepares it for the next season, whether it’s planting flowers, enjoying summer evenings, or getting ready for winter.<br /><br />
      Hiring a professional saves you time and ensures the job is done thoroughly. You can enjoy the beauty of your garden without the hassle of doing it yourself.
      </span> <div className='descImg'><img src={gardenClearance} /></div></div>,
    titleList: <span className='priceTitle'>Garden clean up includes:</span>,
    list: [
      { title: 'Leaf clearance', icon: undefined },
      { title: 'Removal of weeds and all kinds of bramble', icon: undefined },
      { title: 'Trimming and cutting back hedges', icon: undefined },
      { title: 'Pruning or complete removal of bushes, trees, and ivy', icon: undefined },
      { title: 'Lawn mowing', icon: undefined },
      { title: 'Removal of broken fence panels or other garden garbage', icon: undefined },
    ],
    priceTitle: <span className='priceTitle'>Our prices for two persons:</span>,
    listSecondary: [
      { title: 'First hour - 80£', icon: undefined },
      { title: 'Subsequent hour - 60£', icon: undefined },
      { title: '4 hours order - 233 (10% discount)', icon: undefined },
      { title: '8 hours order - 400 (20% discount)', icon: undefined },
    ],

    attention: <span className='attentText'><strong>Attention: </strong> you can save money just ordering other our services together like Garden Clearance, Pressure Washing or Bush and Tree Trimming, to receive up to 20% discount!</span>,
  },
  'Pressure Washing': {
    isHiddenTitle: true,
    category: false,
    description:<div className='jobContentDescription'> <span className='pageDesc'><strong>After Pressure Washing everything looks like new!</strong> <br /><br />

    Pressure washing is the perfect way to make your outdoor spaces look like new again. It removes dirt, mold, and stains from surfaces like driveways, patios, walls, and fences. Regular cleaning keeps these areas in great condition and prevents long-term damage caused by grime or algae.<br /><br />
    Pressure washing improves the appearance of your property, making it look fresh and well-maintained. It’s also a practical way to increase your home’s curb appeal if you’re planning to sell. Plus, it creates a cleaner, safer environment by removing slippery surfaces caused by moss or dirt buildup.<br /><br />
      Let professionals handle the hard work for you. Pressure washing is quick, effective, and a great way to protect your property and keep it looking its best.</span><div className='descImg'><img src={pressureWashing} /></div></div>,
    priceTitle: <span className='priceTitle'>Our prices for two persons:</span>,
    listSecondary: [
      { title: 'First hour - 80£', icon: undefined },
      { title: 'Subsequent hour - 60£', icon: undefined },
      { title: '4 hours order - 233 (10% discount)', icon: undefined },
      { title: '8 hours order - 400 (20% discount)', icon: undefined },
    ],
    attention: <span className='attentText'><strong>Attention: </strong> you can save money just ordering other our services together like Garden Clearance, Pressure Washing or Bush and Tree Trimming, to receive up to 20% discount!</span>,
  },
  'Bush and Tree Trimming': {
    isHiddenTitle: true,
    category: false,
    description:<div className='jobContentDescription'><span className='pageDesc'><strong>Why Bush and Tree Trimming is Important</strong> <br /><br />

    Trimming bushes and trees isn’t just about keeping your garden tidy — it’s essential for their health and growth. Regular trimming removes dead or overgrown branches, helping plants grow stronger and look more beautiful. It also allows sunlight to reach lower branches and improves air circulation, keeping your plants healthy.<br /><br />
    Well-maintained trees and bushes make your garden look neat and inviting. They can also prevent problems like falling branches or overgrown plants blocking pathways or windows. Professional trimming keeps your outdoor space safe, organized, and adds value to your property.<br /><br />
      Leave it to the experts to give your garden the perfect look. Professional bush and tree trimming saves you time and effort while ensuring your plants stay healthy and beautiful year-round.</span><div className='descImg'><img src={trimming} /></div></div>,
    priceTitle: <span className='priceTitle'>Our prices for two persons:</span>,
    listSecondary: [
      { title: 'First hour - 80£', icon: undefined },
      { title: 'Subsequent hour - 60£', icon: undefined },
      { title: '4 hours order - 233 (10% discount)', icon: undefined },
      { title: '8 hours order - 400 (20% discount)', icon: undefined },
    ],
    attention: <span className='attentText'><strong>Attention: </strong>you can save money just ordering other our services together like Lawn Care, Garden Clearance or Pressure Washing, to receive up to 20% discount!
    </span>,
  },
  'Fence Installation' : {
    category: true,
    type: 'L',
    categoryContent: [
      { title:'Wooden Post/Gravel Board' ,
        list:['Time of usage:  up to 15 years','Threated Post:  4x4','Type: Closeboard Panel'],
        price: <span>Average Price per Panel: <strong>£325 </strong></span>,
        img: pf1,
      },
      { title:'Wooden Post/Gravel Board',
        list: ['Time of usage:  up to 20 years','Threated Slatted Post:  4x4','Type: Cedar Horizontal Panel'],
        price: <span>Average Price per Panel:<strong>£720</strong></span> ,
        img: pf2,
      },
      { title:'Concrete Post/Gravel Board',
        list: ['Time of usage:  up to 30 years,panels up to 15 years','Concrete Slatted Post:  4x4','Type: Closeboard Panel'],
        price: <span>Average Price per Panel:<strong>£400 </strong></span> ,
        img: pf3,
      },
      { title:'Concrete Post/Gravel Board' ,
        list: ['Time of usage:  up to 25 years','Concrete Slatted Post:  4x4', 'Type: Cedar Horizontal Panel'],
        price:<span>Average Price per Panel: <strong>£900</strong></span> ,
        img: pf4,
      }],
  },
  'Batten Fencing Softwood': {
    category: true,
    categoryContent: [
      {
        title: 'Average Price per Panel  (1.9m):',
        list: [
          <>Height 5 – 6ft (1.52-1.83m) - <strong>£420</strong></>,
          <>Height 3 – 4ft (0.91-1.52m) - <strong>£380</strong></>,
          <>Height till  3ft ( till 0.91m) - <strong>£320</strong></>,
        ],
        doubleImg: [bf1, bf2],
        tag: true,
      },
      {
        title: 'Average Price per Panel  (1.9m):',
        list: [
          <>Height 5 – 6ft (1.52-1.83m) - <strong>£500</strong></>,
          <>Height 3 – 4ft (0.91-1.52m) - <strong>£430</strong></>,
          <>Height till  3ft ( till 0.91m) - <strong>£370</strong></>,
        ],
        doubleImg: [bf3, bf4],
        tag: true,
      },
    ],
  },
  'Paving': {
    type: 'L',
    category: false,
    price: <> Average Price per Sq.m: <strong>£220 </strong></>,
    description:<>
      <div className='point' style={{ marginTop: '20px' }} /> Concrete Base Minimum 150mm<br />
      <div className='point' /> Any Pattern<br />
    </>,
    images: [pav1, pav2, pav3, pav4, pav5, pav6],
  } ,
  'Block Paving/Driveways': {
    type: 'L',
    category: true,
    categoryContent: [
      {
        title: 'Block Paving',
        list: ['Concrete Base Minimum 150mm', 'Any Pattern'],
        price: <span>Average Price per Sq.m: <strong>£260 </strong></span>,
        doubleImg: [bp4, bp3],
      },
      {
        title: 'Block Paving Driveway',
        list: ['Concrete Base Minimum 200mm', 'Reinforced Steel Mesh', 'Any Pattern'],
        price: <span> Average Price per Sq.m: <strong>£300</strong></span>,
        doubleImg: [bp2, bp5],
      },
    ],
    lastImg: bp1,
  },
  'Drainage': {
    type: 'L',
    category: true,
    categoryContent: [
      { title:'Drainage point',
        list: ['Materials and Labour'],
        price: <span>Average Price per Point: <strong>£150 </strong></span>,
        doubleImg: [dr2, dr1],
      },
      { title:'Drainage Channel',
        list: ['Materials and Labour'],
        price: <span>Average Price per Linear m.: <strong>£150 </strong></span>,
        doubleImg: [dr3, dr4],
      },
      { title:'Paved Manhole',
        list: ['Materials and Labour'],
        price: <span>Average Price per Point: <strong>£800</strong></span>,
        doubleImg: [dr5, dr6],
      },
      { title:'Water Stop Tap Cover',
        list: ['Materials and Labour'],
        price:<span>Average Price per Point: <strong>£120 </strong></span>,
        doubleImg: [dr7, dr8],
      },
    ],
  },
  'Gates': {
    type: 'L',
    category: true,
    categoryContent: [
      { title: 'Metal Gates',
        text:'Time of usage:  up to 15 years',
        subtitle: 'Included:',
        list: ['Metal Gates','Labour'],
        price:<span>Price from : <strong>£375</strong></span> ,
        img: gat4,

      },
      { title: 'Metal Driveway Gates',
        text:'Time of usage:  up to 15 years',
        subtitle: 'Included:',
        list: ['Metal Gates','Labour' ],
        price:<span> Average Price : <strong>£700</strong></span> ,
        img: gat5,
      },
      { title: 'Security Wooden Gate',
        text:'Time of usage:  up to 15 years',
        subtitle: 'Included:',
        list: ['Wooden Door','Hinges and handle','Long Throw lock','Labour' ],
        price: <span> Average Price : <strong>£650</strong></span>,
        doubleImg: [gat1, gat2],
      },
    ],
    lastImg: gat3,
  },
  'Lighting': {
    type: 'L',
    category: false,
    price: <>Average Price per Point: <strong>£200</strong></>,
    images: [lighting5, lighting1, lighting2, lighting6, lighting3, lighting4 ],
  },
  'Planting Flowers and Trees': {
    category: false,
    // price: <>Average Price per Point: <strong>£200</strong></>,
    images: [Planting11, Planting12, Planting13, Planting14, Planting15, Planting16],
    type: 'L',
  },
  'Summer Houses/ Garden Office': {
    type: 'L',
    category: false,
    price: <>Price from: <strong>£1000 per Sq.m</strong></>,
    images: [summer4, summer1, summer3],
    imagesVertical: [summer2],
    // summer5
  },
  'Raised Beds': {
    type: 'L',
    category: true,
    categoryContent: [
      {
        title:'Rendered and Painted',
        subtitle:'Include:',
        list: ['Blocks and aggregates', 'Steel Angle Trim', 'Paint', 'Soil', 'Labour'],
        price: <span>Average Price per Linear m: <strong>£280</strong></span>,
        img: raised1,
      },
      {
        title: 'Wooden Railway Sleepers Beds',
        subtitle:'Include:',
        list: ['Wooden Railway Sleeper', 'Wooden Post', 'Postfix', 'Screws', 'Soil', 'Labour'],
        price: <span>Average Price per Linear m: <strong>£200</strong></span>,
        img: raised2,
      },
      {
        title: 'Blocks with Wooden Screening',
        subtitle:'Include:',
        list: ['Blocks and aggregates', 'Wooden Screening', 'Varnish', 'Soil', 'Labour'],
        price: <span>Average Price per Linear m: <strong>£300</strong></span>,
        img: raised3,
      },
      {
        title: 'Integrated Sitting Area',
        subtitle:'Include:',
        list: ['Blocks and aggregates', 'Steel Angle Trim', 'Wooden Screening', 'Paint', 'Varnish', 'Labour'],
        price: <span>Average Price per Linear m: <strong>£250</strong></span>,
        img: raised4,
      },
    ],
  },
  'Brickwall': {
    type: 'L',
    category: false,
    price: <>Average Price per Linear m:  <strong>£700</strong></>,
    subtitle:'Included:',
    imgFirst: <img width='100%' src={brickwall1} />,
    imgSecondary: <img width='100%' src={brickwall2} />,
    imgThird: <img width='100%' src={brickwall3} />,
    imgFourth: <img width='100%' src={brickwall4} />,
    imgFifth: <img width='100%' src={brickwall5} />,
    list: [
      { title: 'EReinforced Foundation', icon: undefined },
      { title: 'Bricks', icon: undefined },
      { title: 'Mortar', icon: undefined },
      { title: 'Labour', icon: undefined },
      { title: 'Wall Height up to 1.83m (6ft)', icon: undefined },
    ],
  },
  'Landscape Architect': {
    type: 'L',
    category: false,
    images: [land1, land2, land3],
    imagesVertical: [land4],
    list: [
      { title: 'Estimation', icon: undefined },
      { title: 'Landscape Design', icon: undefined },
      { title: 'Sketch Images 3d', icon: undefined },
      { title: 'Plant Legend', icon: undefined },
      { title: 'Description of Works', icon: undefined },
      { title: 'Lighting plan', icon: undefined },
      { title: 'Irrigation Plan', icon: undefined },
    ],
    lastImg: land5,
  },
}
export const project =[
  { name: 'Woodford Green', img: Ready4 },
  { name: 'East Finchley', img: Ready1 },
  { name: 'Muswell Hill', img: Workmoment24 },
  { name: 'Crouch Hill', img: Read9 },
  { name: 'Hadley Wood', img: Ready13 },
  { name: 'Redbridge', img: Ready2 },
  { name: 'Loughton', img: Ready },
  { name: 'Planting', img: Plan },
]

export const initialStateGallery = { name: '' }
export const initialStateModal = { mobileMenuIsOpen: false , contactUsIsOpen: false, successModalIsOpen: false }
export const initialStateListOfJobs = { name: '', flex: 'row', about: false, open: false, activeCategory: '' }
const width = window.innerWidth
export const isMobile = width < 550

export const HOME = '/'
export const GALLERY = '/gallery'
export const JOBS = '/services'
export const CONTACT = '/contact'
export const ABOUT = '/about'

export const navLinks = [
  { name: 'home', path: '/' },
  { name: 'list of services', path: '/services' },
  { name: 'gallery', path: '/gallery' },
  { name: 'contact us', path: '/contact' },
  { name: 'about us', path: '/about' }]

export const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/