/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react'
import { GalleryContext } from '../../../App'
import { projects } from './data'
import { ReactComponent as ToTop } from '../../../image/toTop.svg'
//lib
import { useInView, motion } from 'framer-motion'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { Circles } from 'react-loader-spinner'
//styles
import styles from './index.module.css'
import 'react-photo-view/dist/react-photo-view.css'

const ShowAllGallery = ({ loading, setLoading, time }) => {
  const { projectName } = useContext(GalleryContext)
  const [isVisible, setIsVisible] = useState(true)
  const ref = useRef(null)
  const isInView = useInView(ref)

  useEffect(() => {
    if(!!projectName.name){
      setLoading(true)
      preloaderFnc()
    }
  }, [projectName])

  useEffect(() => {
    setIsVisible(isInView)
  }, [isInView])

  const preloaderFnc = () => {
    setTimeout(() => {
      setLoading(false)
    }, time)
  }
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const vrnBtn = {
    hidden: { display: 'none' },
    enter: { display: 'block' },
  }

  const stylesInfo = { opacity: loading ? 0 : 1, pointerEvents: loading ? 'none' : 'all' }

  const BtnToTop = () => <motion.div
    animate={!isVisible ? 'enter' : 'hidden'}
    transition={{ duration: 0.3 }}
    variants={vrnBtn}
    initial='enter'
    style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
    }}
  >
    <button onClick={toTop} className={styles.toTop} >
      <ToTop />
    </button>
  </motion.div>

  return (
    <div style={{ width: !projectName.name ? 'none' : '100%' }}>
      <div className={styles.gallery}>
        <div ref={ref} />

        <div>
          <div className={styles.spin} style={{ height:loading ? '300px': '5px' }}>
            <Circles
              ariaLabel='circles-loading'
              visible={loading}
              color='#4fa94d'
              width='100%'
              height='50'
            />
          </div>

          {projectName.name && <div style={stylesInfo} className={styles.info}>
            <div className={styles.text}>

              <PhotoProvider>
                <div className={styles.imageBlock}>
                  {projects[projectName.name].map(({ img }) => {
                    return (
                      <PhotoView key={img} src={img}>
                        <img className={styles.image} src={img} />
                      </PhotoView>
                    )
                  })}
                </div>
              </PhotoProvider>

              <br />
              <br />
            </div>
          </div>
          }
        </div>
        {projectName.name && <BtnToTop />}
      </div>
    </div>
  )
}
export default ShowAllGallery
