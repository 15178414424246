import React from 'react'
import Jobs from '../components/ListofJobs/Jobs'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/Contact'
import Hero from '../components/Hero'

export const HomePage = () => {

  return <>
    <Hero />
    <div className='container listOfServices'><Jobs /></div>
    <AboutUs />
    <ContactUs />
  </>
}