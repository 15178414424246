import React from 'react'
import { useLocation } from 'react-router-dom'
import { ABOUT } from '../../utils/data'
import styles from './index.module.css'

const AboutUs = () => {
  const { pathname } = useLocation()
  const getClass = pathname === ABOUT ? styles.activeMain : styles.mainAbout

  return (
    <div className={getClass}
      id='about'
    >
      <h1 className={styles.infoAboutTitle}>About us</h1>
      <div className={styles.content}>
        <div className={styles.infoAboutFirst}>
          <strong className='titleGardens'>Gardens 24/7</strong>
           is a full-service gardening and landscaping company based in Woodford.
           We offer bespoke garden designs at affordable prices whilst offering the highest
           quality and service to our clients.<br /><br />
           We pride ourselves on being a team of highly skilled landscaping professional
           and share 40 years of experience of a great combination of skilled team professionals
           who are ready to handle any job from the conception to its realisation.<br /><br />
           Our dedicated team is ready to assist with improving any of your garden desires
           whether it is just a regular garden maintenance to creating unique landscaping garden designs.<br /><br />
           Gardens 24/7 is your perfect choice for a call to discuss your vision and share the best options
           in support to make your garden looking beautiful, unique and most importantly functionable
           to suit your gardening
           We look forward to hearing from you and making your gardens dreams come true.
        </div>
        <div className={styles.cardAbout} />
      </div>
    </div>
  )
}
export default AboutUs